<template>
  <div>
    <custom-header></custom-header>
    <div class="container">
      <el-row :gutter="10">
        <el-col :span="10">
          <div class="show-preview">
            <viw-modal :content="webUrl"></viw-modal>
            <!-- <div class="show-tab">
              <p>上一位</p>
              <p>下一位</p>
            </div> -->
          </div>
        </el-col>
        <el-col :span="14">
          <div class="show-right">
            <div class="show-erwei">
              <div class="qrcode" id="qrcode" ref="qrcode"></div>
              手机扫码观看
            </div>
            <div style="flex: 1">
              <p class="show-title">{{ detail.aboutName || "" }}</p>
              <p class="show-subtitle">
                {{ detail.name || "" }}
              </p>
            </div>
          </div>
          <div class="show-recommend">
            <p>推荐浏览</p>
            <div class="show-list">
              <img
                v-for="(item, index) in list"
                :key="index"
                :src="item.coverUrl"
                alt=""
                @click="showPreviewModal(item)"
              />
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <custom-footer></custom-footer>
    <preview-modal ref="previewModal"></preview-modal>
  </div>
</template>

<script>
import PreviewModal from "@/components/PreviewModal";
import viwModal from "@/components/viwModal";
import QRCode from "qrcodejs2"; // 引入插件
import {
  selectRandomAtlTime,
  timeDetails,
  isMyself,
  addReadCount,
} from "@/api/index";
export default {
  name: "login",
  components: {
    viwModal,
    PreviewModal,
  },
  data() {
    return {
      list: [],
      detail: {
        aboutName: "",
        name: "",
      },
      webUrl: "",
    };
  },
  created() {
    this.init(this.$route.query.id);
  },
  methods: {
    showPreviewModal(item) {
      this.$refs.previewModal.show(item.id);
      this.visible = false;
      // if (item.device == 1) {
      //   this.$refs.previewModal.show(item.id);
      // } else {
      //   this.$router.push({
      //     path: "/preview",
      //     query: { id: item.id },
      //   });
      // }
    },
    init(id) {
      console.log("id", id);
      if (id) {
        this.id = id;
        this.timeDetails();
        this.addReadCount();
        this.getWebUrl();
        this.selectRandomAtlTime();
      } else {
        this.$router.go(-1);
      }
    },
    qrcode() {
      new QRCode("qrcode", {
        width: 120, // 设置宽度，单位像素
        height: 120, // 设置高度，单位像素
        colorLight: "#fff",
        text: this.webUrl, // 设置二维码内容或跳转地址
      });
    },
    addReadCount() {
      addReadCount({ id: this.id, type: 1 }).then(() => {});
    },
    timeDetails() {
      timeDetails({ timeId: this.id }).then((res) => {
        this.detail = res.data;
      });
    },
    getWebUrl() {
      isMyself({ id: this.id, type: 0 }).then((res) => {
        if (res.data == true) {
          this.webUrl =
            "https://go.yunzhongci.com/?timerid=" +
            this.id +
            "&token=" +
            this.$store.state.token +
            "&isMy=true";
        } else {
          this.webUrl =
            "https://go.yunzhongci.com/?timerid=" +
            this.id +
            "&token=" +
            this.$store.state.token;
        }
        console.log("webUrl", this.webUrl);
        if (document.getElementById("qrcode").innerHTML) {
          document.getElementById("qrcode").innerHTML = "";
        }
        this.qrcode();
      });
    },
    selectRandomAtlTime() {
      selectRandomAtlTime().then((res) => {
        console.log(res);
        this.list = res.data;
      });
    },
  },
  watch: {
    $route: {
      handler: function (val) {
        this.init(val.query.id);
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.container {
  width: 100%;
  margin: 0 auto;
  padding: 10% calc((100% - 1150px) / 2);
  background: url("~@/assets/images/Exhibitionbg.png") no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}
.show-preview {
  display: flex;
  // justify-content: center;
  align-items: center;
  .show-tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    p {
      writing-mode: tb-rl;
      background: #e9e9e9;
      font-size: 16px;
      color: #999;
      padding: 10px 5px;
    }
    p:first-child {
      margin-bottom: 50px;
    }
  }
}
.show-right {
  display: flex;
  .show-erwei {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 3%;
    color: #666;
    font-size: 12px;

    .qrcode {
      width: 120px;
      height: 120px;
      padding: 10px;
      background: #fff;
      margin-bottom: 10px;
    }
  }

  .show-title {
    color: #5d6063;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .show-subtitle {
    width: 70%;
    color: #666666;
    line-height: 36px;
    letter-spacing: 2px;
    font-size: 14px;
  }
}
.show-recommend {
  p {
    color: #333;
    font-size: 20px;
    font-weight: bold;
    margin: 50px 0 20px;
  }
  .show-list {
    display: flex;
     width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    img {
      width: 150px;
      margin: 10px;
      border-radius: 10px;
      cursor:pointer
    }
  }
}
</style>

